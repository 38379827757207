<template>
  <div class="main main--internal">
    <div class="page-header__container">
      <h1 class="page-header">Поддержка</h1>
    </div>
    <article class="article">
      <div class="article__block">
        <EditorJSComponent :text="support.text"/>
      </div>
      <div class="article__faq" v-if="faq && faq.length">
        <h2 class="article__subtitle">Вопросы и ответы</h2>
        <ul class="article__faq__list">
          <li class="article__faq__list__item" :class="{ active: activeFaq === index }" v-for="(item, index) in faq" :key="index">
            <div class="article__faq__list__item__header" @click="activeFaq = index">
              <h3>{{ item.question }}</h3>
              <div>
                <PlusIcon/>
                <MinusIcon/>
              </div>
            </div>
            <p class="article__faq__list__item__text">{{ item.answer }}</p>
          </li>
        </ul>
      </div>
    </article>
  </div>
</template>

<script>
import PlusIcon from 'components/svg/Plus.vue'
import MinusIcon from 'components/svg/Minus.vue'
import EditorJSComponent from 'components/EditorJSComponent.vue'

export default {
  name: 'SupportPage',
  components: { EditorJSComponent, MinusIcon, PlusIcon },
  async asyncData ({ store }) {
    await store.dispatch('GET_SUPPORT_PAGE')
  },
  data () {
    return {
      activeFaq: null
    }
  },
  computed: {
    navigator () {
      return this.$store.state.support_page.navigator
    },
    support () {
      return this.$store.state.support_page.support
    },
    faq () {
      return this.$store.state.support_page.support.questions
    },
    advantages () {
      return this.$store.state.support_page.advantages
    }
  },
  metaInfo () {
    const navigator = this.$store.state.support_page.navigator
    return this.$seo(
      'common',
      navigator.title,
      navigator.meta_keywords,
      navigator.description,
      navigator.title,
      null,
      navigator.description
    )
  }
}
</script>
